import Vue from 'vue'

import {
  Anchor,
  Alert,
  BackTop,
  Button,
  Card,
  Carousel,
  Col,
  Divider,
  Icon,
  Layout,
  message,
  Progress,
  Row,
  Spin,
  Table,
  Tabs,
  Tag,
  Timeline,
  Tooltip,
} from 'ant-design-vue'

import App from './App.vue'

Vue.config.productionTip = false

Vue.prototype.$message = message;

Vue.use(Anchor);
Vue.use(Alert);
Vue.use(BackTop);
Vue.use(Button);
Vue.use(Card);
Vue.use(Carousel);
Vue.use(Col);
Vue.use(Divider);
Vue.use(Icon);
Vue.use(Layout);
Vue.use(Progress);
Vue.use(Row);
Vue.use(Spin);
Vue.use(Table);
Vue.use(Tabs);
Vue.use(Tag);
Vue.use(Timeline);
Vue.use(Tooltip);

new Vue({
  render: h => h(App),
}).$mount('#app')
