<template>
  <div id="antd-vue-resume">
    <a-layout>
      <a-layout>
        <a-layout-content :style="{ padding: '20px' }">
          <div id="resume-about-me">
            <a-card :bordered="false">
              <a-row type="flex" justify="start" :style="{ marginBottom: '10px' }">
                <a-col :span="20">
                  <h2>陈庚茂(Camry)</h2>
                </a-col>
                <a-col :span="4">
                  <div class="noprint">
                    <a-button type="primary" icon="printer" @click="clickPrint">打印</a-button>
                  </div>
                </a-col>
              </a-row>
              <a-card type="inner" title="系统架构师 &amp; 资深服务端开发工程师">
                八年以上项目开发经验；三年以上系统架构经验及项目管理经验。
                <a-divider dashed />
                拥有自主研发产品 <a href="http://www.teamlogs.com" target="_blank"><a-tag color="purple">Teamlogs</a-tag></a>。
                <a-divider dashed />
                拥有自主研发 MySQL 数据库差异工具 <a href="https://github.com/camry/go-mysqldiff" target="_blank"><a-tag color="purple">go-mysqldiff</a-tag></a> <a href="https://github.com/camry/python-mysqldiff" target="_blank"><a-tag color="purple">python-mysqldiff</a-tag></a>
                <a-divider dashed />
                熟悉 <a-tag color="purple">Docker</a-tag>可自主搭建 Docker Registry TLS 私有仓库及制作项目需要的 Docker 镜像。
                <a-divider dashed />
                熟悉 <a-tag color="purple">Python</a-tag>可使用 <a href="https://palletsprojects.com/p/click/" target="_blank"><a-tag color="purple">Click</a-tag></a> 框架制作 Linux 命令行工具。
                <a-divider dashed />
                熟悉 <a-tag color="purple">Golang</a-tag>可使用 <a href="https://github.com/spf13/cobra"><a-tag color="purple">spf13/cobra</a-tag></a> 框架制作 Linux 命令行工具。
                <a-divider dashed />
                熟悉 PHP 扩展 <a href="https://www.swoole.com" target="_blank"><a-tag color="purple">Swoole</a-tag></a>可自主编写服务框架；熟悉 <a-tag color="purple">Laravel</a-tag>可自主改造适合自己项目的底层架构。
                <a-divider dashed />
                熟悉 Elasticsearch+Kibana+Logstash+X-Pack 分布式全文搜索引擎的配置。
                <a-divider dashed />
                熟悉 <a-tag color="purple">Git</a-tag>，能够使用GitLab自主搭建本地Git服务器。
              </a-card>
            </a-card>
          </div>
          <div id="resume-person-info">
            <a-card style="margin-top: 24px" :bordered="false" title="个人信息">
              <a-row>
                <a-col :sm="2">邮箱</a-col>
                <a-col :sm="18">camry.chen#foxmail.com(请把#替换为@)</a-col>
              </a-row>
              <a-divider dashed />
              <a-row>
                <a-col :sm="2">手机</a-col>
                <a-col :sm="18">159 7208 1059</a-col>
              </a-row>
              <a-divider dashed />
              <a-row>
                <a-col :sm="2">产品</a-col>
                <a-col :sm="18">
                  <a href="http://www.teamlogs.com" target="_blank">http://www.teamlogs.com</a>
                </a-col>
              </a-row>
              <a-divider dashed />
              <a-row>
                <a-col :sm="2">GitHub</a-col>
                <a-col :sm="18">
                  <a href="https://github.com/camry" target="_blank">https://github.com/camry</a>
                </a-col>
              </a-row>
              <a-divider dashed />
              <a-row>
                <a-col :sm="2">Packagist</a-col>
                <a-col :sm="18">
                  <a href="https://packagist.org/search/?q=camry" target="_blank">https://packagist.org/search/?q=camry</a>
                </a-col>
              </a-row>
            </a-card>
          </div>
          <div id="resume-employment">
            <a-card style="margin-top: 24px" :bordered="false" title="工作经验">
              <a-timeline>
                <a-timeline-item color="blue">
                  <a-icon slot="dot" type="clock-circle-o" style="font-size: 16px;" />
                  <a-tag>2010年</a-tag>入职
                  <a-tag color="purple">武汉腾翔互创网络有限公司</a-tag>担任
                  <a-tag color="blue">PHP程序员 &amp; 项目管理</a-tag>
                </a-timeline-item>
                <a-timeline-item>
                  2010 - 2011 从事 搜马汽车人才网 项目开发(DiscuzX二次开发)；
                  <br />2011 - 2012 负责 德州扑克游戏 前端开发(HTML5+CSS3+JavaScript)；
                  <br />2012 - 2014 负责公司产品 完美故事客户端 的API接口、App下载页、Web端（采用了Handlebars模板）及后台管理的开发，在此期间带领整个团队研发出了针对Kohana的业务逻辑服务层Service架构；负责 Intreest 后端开发（Kohana框架），主要为IOS和Android手机客户端提供API接口。
                  <br />
                </a-timeline-item>
                <a-timeline-item color="blue">
                  <a-icon slot="dot" type="clock-circle-o" style="font-size: 16px;" />
                  <a-tag>2014年6月</a-tag>入职
                  <a-tag color="purple">武汉灏信信息技术服务有限公司</a-tag>担任
                  <a-tag color="blue">PHP开发经理</a-tag>
                </a-timeline-item>
                <a-timeline-item>
                  <h3>2014.6至2015.2 负责“微信公众号认证作业系统”的重构</h3>
                  <strong>老库面临的问题：</strong>
                  <ol>
                    <li>派单异常，多表写入数据超时；</li>
                    <li>数据量过大（千万级），查询速度超慢；</li>
                    <li>系统流程设计不合理，造成业务效率低下；</li>
                    <li>代码结构混乱，数据库表结构设计不合理，很难维护和扩展；</li>
                    <li>SQL语句多重子查询，表结构不合理导致；</li>
                    <li>索引使用不合理；</li>
                    <li>代码未托管至版本库，不好协同开发。</li>
                  </ol>
                  <br />
                  <strong>新库解决方案：</strong>
                  <ul>
                    <li>
                      <strong>系统架构：</strong>使用Laravel框架，采用规范的PHP包管理Composer进行维护；采用专业的Git版本库控制工具；采用Redis做数据缓存处理和派单消息队列（解决派单异常）；腾讯认证接口数据通信采用AES加密传输。
                    </li>
                    <li>
                      <strong>数据库：</strong>采用MySQL集群Master-Slaves主从和表分区架构方案，数据表结构规范设计，避免SQL语句链表查询，根据检索条件建立合理的索引，解决查询性能瓶颈。
                    </li>
                    <li>
                      <strong>流程设计：</strong>重新规范业务流程，视图简化操作元素，避免数据重复写入数据库，提高业务效率。
                    </li>
                    <li>
                      <strong>技术突破：</strong>电话呼叫系统和作业系统的整合，可以直接通过作业系统进行拨打电话和接听电话。
                    </li>
                  </ul>
                  <br />
                  <dl>
                    <dt>2015.2至2015.4</dt>
                    <dd>负责“灏信身份证查询系统”和“灏信销售系统”的研发。</dd>
                    <dt>2015.4至2015.6</dt>
                    <dd>负责申请“软件著作权”、“高新技术企业”、“ISO27001”、“ISO9001”、“企业征信”相关技术资料的编写。</dd>
                    <dt>2015.6至2015.10</dt>
                    <dd>负责“灏信品牌库”、“灏信顺丰招商系统”、“灏信顺丰认证系统”的研发。</dd>
                    <dt>2015.10至2016.2</dt>
                    <dd>负责“灏信1号店认证系统”的重构，采用了“微信公众号认证作业系统”的新库解决方案。</dd>
                    <dt>2016.2至2016.5</dt>
                    <dd>负责2015年度项目“软件著作权”的申请、2015年度“高新技术企业”的项目申报加计扣除。</dd>
                    <dt>2016.5至2016.8</dt>
                    <dd>
                      负责“灏信资源库（信息资源整合）”的研发
                      <br />
                      <strong>新技术点：</strong>搜索采用 Algolia 的分布式搜索网络架构；采用Markdown更新信息资源。
                    </dd>
                    <dt>2016.8至2016.9</dt>
                    <dd>负责“灏信1号店认证系统（线上质检）”功能的研发。</dd>
                    <dt>2016.9至2017.1 解决数据库性能瓶颈，采用数据库分布式解决方案</dt>
                    <dd>
                      <strong>架构方案A：</strong>采用Keepalived+Haproxy+Mycat+Galera Cluster For MySQL 高可用与负载均衡集群
                      <br />
                      <strong>架构方案B：</strong>采用Keepalived+Haproxy+Mycat+MySQL双主双从 高可用与负载均衡
                      <br />
                      <strong>方案描述：</strong>方案A和方案B根据不同应用场景进行选择。 Keepalived负责为该服务器抢占VIP（虚拟IP）； Haproxy负责将VIP的请求分发到Mycat上，起到负载均衡的作用； Mycat主要做分片（分库分表）。
                      <br />
                    </dd>
                  </dl>
                  <h4>
                    <strong>工作内容：</strong>负责组织制定各种技术标准和技术规范并保证实施，参与技术方向研究和总体规划，发展新项目，带领技术团队进行项目攻关。负责公司所有作业系统的维护及更新。
                  </h4>
                </a-timeline-item>
                <a-timeline-item color="blue">
                  <a-icon slot="dot" type="clock-circle-o" style="font-size: 16px;" />
                  <a-tag>2017年1月</a-tag>入职<a-tag color="purple">武汉阿托普软件有限公司</a-tag>担任<a-tag color="blue">PHP项目经理</a-tag>
                </a-timeline-item>
                <a-timeline-item>
                  主要负责德国易游 TRAVELEASY 公司旗下全额退税服务产品（易全退 TAX FREE EASY及TFE退税管理平台）和 欧洲旅游管理平台（teCRM）的维护和新需求的开发。
                  <br />
                  <strong>工作内容：</strong>负责和德国那边的需求沟通，带领技术团队进行项目攻关。
                </a-timeline-item>
                <a-timeline-item color="red">
                  <a-icon slot="dot" type="clock-circle-o" style="font-size: 16px;" />
                  <a-tag>2017年6月</a-tag>入职<a-tag color="purple">武汉动游科技有限公司</a-tag>担任<a-tag color="blue">资深服务端开发工程师</a-tag>
                </a-timeline-item>
                <a-timeline-item color="red">
                  <h3>主要负责《末日血战》手游核心玩法功能开发：</h3>
                  <ul>
                    <li>英雄系统（英雄召唤、英雄分解、英雄合成、英雄升级、英雄升阶、英雄阵容、英雄碎片出售）</li>
                    <li>装备系统（穿戴装备、一键穿戴、脱装、装备合成、装备出售）</li>
                    <li>关卡系统（关卡挂机、挂机领取资源和战利品、挂机战利品定时器）</li>
                    <li>道具系统</li>
                    <li>礼包系统</li>
                    <li>聊天系统</li>
                    <li>好友系统</li>
                    <li>充值与VIP系统</li>
                    <li>宝石系统</li>
                    <li>英雄任务栏系统</li>
                    <li>家园系统</li>
                    <li>小红点功能</li>
                    <li>游戏活动统一规划设计</li>
                    <li>头像功能</li>
                    <li>Google、Apple、Facebook及国内游戏渠道 SDK 接入</li>
                    <li>TapDB、Reyun、数数及第三方统计平台接入</li>
                    <li>16星英雄远征副本地图系统</li>
                  </ul>
                  <h3>制作命令行工具：</h3>
                  <ul>
                    <li>游戏服、跨服合区工具</li>
                    <li>清理账号工具</li>
                    <li>过期的数据清理工具</li>
                    <li>版本打包工具</li>
                    <li><a-tag color="purple">game-cli</a-tag>游戏命令工具（Docker容器及镜像管理、项目管理、服务器管理）</li>
                    <li>
                      针对 MySQL 的数据库差异 SQL 工具
                      <a href="https://github.com/camry/go-mysqldiff" target="_blank"><a-tag color="purple">go-mysqldiff</a-tag></a>
                      <a href="https://github.com/camry/python-mysqldiff" target="_blank"><a-tag color="purple">python-mysqldiff</a-tag></a>
                    </li>
                  </ul>
                  <h3>游戏数据日志服务：</h3>
                  <ul>
                    <li><strong>Flume 集群：</strong>负责收集消息并写入 Kafka 和 RabbitMQ。</li>
                    <li><strong>Kafka 集群：</strong>处理玩家游戏日志消费（Res 日志，战报日志，充值日志，统计日志）。</li>
                    <li><strong>Elasticsearch 集群：</strong>存储玩家消费获得的 Res 日志 和 统计日志。</li>
                    <li><strong>Go 版 game-service-api 服务：</strong>处理 Kafka 和 RabbitMQ 消费、ES 写入、DB 写入、定时器管理及 API 接口。</li>
                  </ul>
                  在职中，待完善
                  <span class="noprint">
                    <a-spin size="small">
                      <a-icon slot="indicator" type="loading" spin />
                    </a-spin>
                  </span>
                  ...
                </a-timeline-item>
              </a-timeline>
            </a-card>
          </div>
          <div id="resume-education">
            <a-card style="margin-top: 24px" :bordered="false" title="教育经历">
              <a-row>
                <a-col :span="6">
                  <h3>湖北第二师范学院</h3>
                </a-col>
                <a-col :span="2">
                  <a-tag color="purple">专科</a-tag>
                </a-col>
                <a-col :span="5">
                  <a-tag color="blue">计算机应用技术</a-tag>
                </a-col>
                <a-col :span="11">主修微机原理及接口、计算机维护与维修、多媒体应用和网络应用技术。</a-col>
              </a-row>
              <a-row>
                <a-col :span="6">
                  <h3>华中科技大学</h3>
                </a-col>
                <a-col :span="2">
                  <a-tag color="purple">本科</a-tag>
                </a-col>
                <a-col :span="5">
                  <a-tag color="blue">计算机科学与技术</a-tag>
                </a-col>
                <a-col :span="11">主修离散数学、计算机组成原理、操作系统原理、数据库系统原理、编译原理、面向对象程序设计、计算机网络、软件工程。</a-col>
              </a-row>
            </a-card>
          </div>
          <div id="resume-skills">
            <a-card style="margin-top: 24px" :bordered="false" title="技能">
              <a-card type="inner" title="领域">
                <a-row :bordered="true">
                  <a-col :span="5">Ant Design Vue</a-col>
                  <a-col :span="4">
                    <a-progress :percent="80" size="small" status="active" />
                  </a-col>
                  <a-col :span="5" :offset="4">Python</a-col>
                  <a-col :span="4">
                    <a-progress :percent="70" size="small" status="active" />
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="5">Linux</a-col>
                  <a-col :span="4">
                    <a-progress :percent="90" size="small" status="active" />
                  </a-col>
                  <a-col :span="5" :offset="4">Docker</a-col>
                  <a-col :span="4">
                    <a-progress :percent="88" size="small" status="active" />
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="5">Swoole</a-col>
                  <a-col :span="4">
                    <a-progress :percent="90" size="small" status="active" />
                  </a-col>
                  <a-col :span="5" :offset="4">PHP7</a-col>
                  <a-col :span="4">
                    <a-progress :percent="98" size="small" status="active" />
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="5">Golang</a-col>
                  <a-col :span="4">
                    <a-progress :percent="80" size="small" status="active" />
                  </a-col>
                  <a-col :span="5" :offset="4">Redis</a-col>
                  <a-col :span="4">
                    <a-progress :percent="98" size="small" status="active" />
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="5">Kafka</a-col>
                  <a-col :span="4">
                    <a-progress :percent="80" size="small" status="active" />
                  </a-col>
                  <a-col :span="5" :offset="4">RabbitMQ</a-col>
                  <a-col :span="4">
                    <a-progress :percent="80" size="small" status="active" />
                  </a-col>
                </a-row>
              </a-card>
              <a-card type="inner" title="开发" :style="{ marginTop: '16px' }">
                <a-row>
                  <a-col :span="4">系统</a-col>
                  <a-col :span="20">
                    <a-tag color="blue">CentOS</a-tag>
                    <a-tag color="blue">Deepin</a-tag>
                    <a-tag>Windows</a-tag>
                    <a-tag color="blue">Docker</a-tag>
                  </a-col>
                </a-row>
                <a-divider dashed />
                <a-row>
                  <a-col :span="4">编程语言</a-col>
                  <a-col :span="20">
                    <a-tag color="blue">PHP7</a-tag>
                    <a-tag color="blue">Python</a-tag>
                    <a-tag color="blue">Golang</a-tag>
                    <a-tag>Java</a-tag>
                    <a-tag color="blue">Ant Design</a-tag>
                    <a-tag color="blue">Vue</a-tag>
                  </a-col>
                </a-row>
                <a-divider dashed />
                <a-row>
                  <a-col :span="4">框架</a-col>
                  <a-col :span="20">
                    <a-tag color="blue">Swoole</a-tag>
                    <a-tag color="blue">Laravel</a-tag>
                    <a-tag color="blue">Goframe</a-tag>
                    <a-tag color="blue">Gin</a-tag>
                    <a-tag color="blue">Echo</a-tag>
                    <a-tag>Spring</a-tag>
                  </a-col>
                </a-row>
                <a-divider dashed />
                <a-row>
                  <a-col :span="4">缓存</a-col>
                  <a-col :span="20">
                    <a-tag color="blue">Redis</a-tag>
                    <a-tag>Memcache</a-tag>
                  </a-col>
                </a-row>
                <a-divider dashed />
                <a-row>
                  <a-col :span="4">队列</a-col>
                  <a-col :span="20">
                    <a-tag color="blue">Kafka</a-tag>
                    <a-tag color="blue">RabbitMQ</a-tag>
                    <a-tag color="blue">NSQ</a-tag>
                    <a-tag color="blue">Beanstalkd</a-tag>
                    <a-tag color="blue">SplQueue</a-tag>
                  </a-col>
                </a-row>
              </a-card>
            </a-card>
          </div>
          <div id="resume-open-source-project">
            <a-card style="margin-top: 24px" :bordered="false" title="开源项目">
              <dl>
                <dt>
                  <h2>
                    <a href="https://github.com/camry/go-mysqldiff" target="_blank">go-mysqldiff</a>
                  </h2>
                </dt>
                <dd>
                  <a-tag color="blue">Golang</a-tag>针对 MySQL 数据库表结构的差异 SQL 工具。
                </dd>
              </dl>
              <a-divider dashed />
              <dl>
                <dt>
                  <h2>
                    <a href="https://github.com/camry/python-mysqldiff" target="_blank">python-mysqldiff</a>
                  </h2>
                </dt>
                <dd>
                  <a-tag color="blue">Python</a-tag>针对 MySQL 数据库表结构的差异 SQL 工具。
                </dd>
              </dl>
              <a-divider dashed />
              <dl>
                <dt>
                  <h2>
                    <a href="https://github.com/camry/navicat-crypt" target="_blank">navicat-crypt</a>
                  </h2>
                </dt>
                <dd>
                  <a-tag color="blue">Golang</a-tag>Navicat 加密解密。
                </dd>
              </dl>
              <a-divider dashed />
              <dl>
                <dt>
                  <h2>
                    <a href="https://github.com/camry/nsqclient-php" target="_blank">NSQ PHP Client</a>
                  </h2>
                </dt>
                <dd>
                  基于&nbsp;
                  <a-tag color="blue">moolex/nsqclient-php</a-tag>改造针对 NSQ 的 PHP客户端。
                </dd>
              </dl>
              <a-divider dashed />
              <dl>
                <dt>
                  <h2>
                    <a href="https://github.com/camry/atom" target="_blank">Atom</a>
                  </h2>
                </dt>
                <dd>PHP唯一ID生成器，基于Twitter雪花算法。</dd>
              </dl>
              <a-divider dashed />
              <dl>
                <dt>
                  <h2>
                    <a href="https://github.com/camry/dove-framework" target="_blank">Dove Framework</a>
                  </h2>
                </dt>
                <dd>
                  基于 <a-tag color="blue">php7</a-tag> <a href="https://www.swoole.com" target="_blank"><a-tag color="blue">swoole</a-tag></a> 的项目基础框架。
                </dd>
              </dl>
              <a-divider dashed />
              <dl>
                <dt>
                  <h2>
                    <a href="https://github.com/camry/dove-framework-stubs" target="_blank" >Dove Framework Stubs</a>
                  </h2>
                </dt>
                <dd>
                  基于 <a href="https://github.com/camry/dove-framework" target="_blank"><a-tag color="blue">dove-framework</a-tag></a> 框架的语法提示。
                </dd>
              </dl>
              <a-divider dashed />
            </a-card>
          </div>
          <div id="resume-hobbies">
            <a-card style="margin-top: 24px" :bordered="false" title="兴趣爱好">
              <a-tooltip placement="top">
                <template slot="title">
                  <span>我爱智力 &amp; 动作游戏</span>
                </template>
                <a-button type="dashed">游戏</a-button>
              </a-tooltip>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>我爱科幻 &amp; 动作电影</span>
                </template>
                <a-button type="dashed" :style="{ marginLeft: '10px' }">电影</a-button>
              </a-tooltip>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>我爱听歌，来减压</span>
                </template>
                <a-button type="dashed" :style="{ marginLeft: '10px' }">音乐</a-button>
              </a-tooltip>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>我爱篮球 &amp; 乒乓球</span>
                </template>
                <a-button type="dashed" :style="{ marginLeft: '10px' }">运动</a-button>
              </a-tooltip>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>我爱好拍照</span>
                </template>
                <a-button type="dashed" :style="{ marginLeft: '10px' }">拍照</a-button>
              </a-tooltip>
            </a-card>
          </div>
          <div id="resume-product-show" class="noprint">
            <a-card style="margin-top: 24px" :bordered="false" title="产品展示">
              <a-tabs defaultActiveKey="5" tabPosition="right" :style="{ minHeight: '600px'}">
                <a-tab-pane tab="Teamlogs" key="1">
                  <h1>
                    <a href="http://www.teamlogs.com/" target="_blank">Teamlogs</a>
                  </h1>
                  <a-row>
                    <a-col :span="24">
                      <h3>团队工作日志软件工具</h3>
                      <p>最好用的团队工作日志软件，便捷记录，详细统计，适合小团队的在线工时记录和统计软件,打开浏览器即可开始使用，无需安装部署。</p>
                      <h4>记录和回顾</h4>
                      <p>没有各种繁复琐杂的表格，记录工时变得超级简单，不再是一件麻烦事。</p>
                      <h4>共享 - 团队日志</h4>
                      <p>团队里每个人每天记录的日志都会汇总在团队日志里。只需要查看团队日志即可掌握今天的团队工作情况，再也不需要每天花一个小时开《今日进度汇报》会议了。</p>
                      <h4>统计 - 在线统计</h4>
                      <p>你可以从成员贡献和分类占比去查看整个团队的工时记录情况。还可以细化分析，查看一个人的工作主要是哪些分类，或者一个分类的工作量主要是哪几位成员完成的。</p>
                      <h4>导出 - 更详细的分析</h4>
                      <p>提供记录导出成 .XLS 文件的功能，你可以用 Excel 这样的工具进行更详细的分析。</p>
                      <img src="~@/assets/product/01.jpg" />
                    </a-col>
                  </a-row>
                </a-tab-pane>
                <a-tab-pane tab="微信认证" key="2">
                  <h1>微信认证系统</h1>
                  <a-row>
                    <a-col :span="24">
                      <p>微信公众号认证是微信公众平台为了确保公众帐号的信息的真实性、安全性，目前提供给微信公众服务号进行微信认证的服务。</p>
                      <img src="~@/assets/product/02.jpg" />
                    </a-col>
                  </a-row>
                </a-tab-pane>
                <a-tab-pane tab="1号店认证" key="3">
                  <h1>1号店认证系统</h1>
                  <a-row>
                    <a-col :span="24">
                      <p>本系统主要针对1号店入驻电商的企业或个人，进行资料、资质的认证及审核。</p>
                      <img src="~@/assets/product/03.jpg" />
                    </a-col>
                  </a-row>
                </a-tab-pane>
                <a-tab-pane tab="机构投资者" key="4">
                  <h1>机构投资者</h1>
                  <a-row>
                    <a-col :span="24">
                      <p>《机构投资者》总部在美国纽约，隶属“欧元机构投资者”集团，它是世界上最有影响力的金融杂志之一，它主要为华尔街、世界金融机构的精英们提供各方面的行业研究报告和金融资讯。《机构投资者在线》是《机构投资者》的中文服务平台，向大中华地区的投资者们提供世界上最前沿的行业分析和金融动态。</p>
                      <img src="~@/assets/product/04.jpg" />
                    </a-col>
                  </a-row>
                </a-tab-pane>
                <a-tab-pane tab="手游 - 末日血战" key="5">
                  <h1>
                    <a href="http://www.idongyou.com/mrxz/mrxz.html" target="_blank">手游 - 末日血战</a>
                  </h1>
                  <a-row>
                    <a-col :span="24">
                      <p>《末日血战》是一款放置类卡牌手游。<br>游戏中包括战役挂机、竞技场、公会战、末世之塔、战役等诸多玩法，是一款挂机角色养成类，点段时间可以玩，无需操作的放置战斗的游戏。<br>玩家们积极备战，打造属于自己的英雄小队，共同征战丧尸爆发的末世界。</p>
                      <img src="~@/assets/product/05.jpg" width="1024" />
                    </a-col>
                  </a-row>
                </a-tab-pane>
              </a-tabs>
            </a-card>
          </div>
        </a-layout-content>
        <a-layout-sider :style="{ background: '#f0f2f5', marginTop: '100px' }" class="noprint">
          <a-anchor :affix="true">
            <a-anchor-link href="#resume-about-me" title="关于我" />
            <a-anchor-link href="#resume-person-info" title="个人信息" />
            <a-anchor-link href="#resume-employment" title="工作经验" />
            <a-anchor-link href="#resume-education" title="教育经历" />
            <a-anchor-link href="#resume-skills" title="技能" />
            <a-anchor-link href="#resume-open-source-project" title="开源项目" />
            <a-anchor-link href="#resume-hobbies" title="兴趣爱好" />
            <a-anchor-link href="#resume-product-show" title="产品展示" />
          </a-anchor>
        </a-layout-sider>
      </a-layout>
      <a-layout-footer :style="{ textAlign: 'center', padding: '0 0 14px 0' }">© Camry Chen - 2021</a-layout-footer>
    </a-layout>
    <a-back-top />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.$message.success("简历加载完成");
  },
  methods: {
    clickPrint() {
      if (window.print) {
        window.print();
      }
    }
  }
};
</script>

<style>
#antd-vue-resume .ant-layout {
  height: 100%;
  background: #f0f2f5 url(~@/assets/background.svg) 34%;
}
#resume-about-me .ant-card-body {
  padding: 24px;
}
</style>