<template>
  <div id="app">
    <Resume msg="Welcome to Your Resume App"/>
  </div>
</template>

<script>
import Resume from './components/Resume.vue'

export default {
  name: 'app',
  components: {
    Resume
  }
}
</script>